<template>
  <div>
    <tabbed-display
      :tabs="tabs"
      :data="data"
      @finished="finished"
      @quited="quited"
      v-if="loaded"
    ></tabbed-display>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { Notification } from "@/helpers/notifications";
import store from "@/store";
import axios from "@/requests/axios";
import { execute_json } from "@/helpers/requests";

export default {
  data() {
    return {
      data: {
        employee: {
          value: null,
          fullname: null,
        },
        order: {
          selected: null,
          subtype: null,
        },
        quantity: {
          value: null,
        },
      },
      loaded: false,
    };
  },
  created() {
    if (this.selections.order && this.selections.order._subtype != 'planned') {
      this.data.order.selected = this.selections.order;
      this.data.order.subtype = this.selections.order._subtype;
      this.data.order.jumped = true;
    }
    store.commit("bottomnav/disable");
    let promises = [];
    if (this.shouldFetchSignedIn) {
      promises.push(
        store.dispatch("machines/loadSignedInForMachine", this.machine_id)
      );
    }
    Promise.all(promises).then(() => {
      this.loaded = true;
      console.log("fully loaded..");
    });
  },
  methods: {
    finished() {
      let note = Notification.request({
        message: "Reporting quantity..",
      });
      execute_json("receive_good_qty", {
        machine_id: this.$route.params.machine,
        card_no: this.data.order.selected.card_no,
        good_qty: this.data.quantity.value,
        employee: this.data.employee.value || "",
      })
        .then(({ data }) => {
          note.ok({
            message: "Quantity reported!",
          });
          this.quited();
        })
        .catch((err) => {
          note.fail({
            message: "Something went wrong!",
          });
        });
    },
    quited() {
      this.$router.push(this.$route.path.replace("/report-quantity", ""));
    },
  },
  computed: {
    ...mapState({
      bottomNav: (state) => state.bottomnav,
      notifications: (state) => state.notifications.index,
      selections: (state) => state.selections,
    }),
    ...mapGetters({
      configByMachine: "machines/configByMachine",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    shouldFetchSignedIn() {
      return (
        this.machineHasSf(this.machine_id, "18") &&
        this.machineHasSf(this.machine_id, "178")
      );
    },
    hasSf362() {
      return this.machineHasSf(this.machine_id, "362");
    },
    config() {
      return this.configByMachine(this.$route.params.machine);
    },
    tabs() {
      let tabs = [];
      //do a check to see if we need an order
      let initialShow = this.data.order.selected === null;
      tabs.push({
        label: "order",
        tlabel: "24",
        component:
          this.data.order._subtype === "finished"
            ? "finished-order"
            : this.data.order.subtype === "planned"
            ? "planned-order"
            : "running-order",
        disabled: false,
        initialShow: initialShow,
        dataKey: "order",
      });
      let showEmployee = this.config.enter_good === "2";
      //do a check to see if we need an employee
      tabs.push({
        label: "employee",
        tlabel: "578",
        component: "employee",
        initialShow: showEmployee,
        disabled: !showEmployee,
        guide: "Config: enter_good=2",
        dataKey: "employee",
      });
      //do a check if we need qty
      let qty = {
        label: "quantity",
        tlabel: "581",
        component: "quantity",
        disabled: false,
        initialShow: 1,
        dataKey: "quantity",
        sideComponent: this.hasSf362 ? "good-history" : null,
        reporter: [
          {
            tlabel: "67",
            val: this.data.order.selected
              ? this.data.order.selected.work_order_no
              : "-",
          },
          {
            tlabel: "578",
            val: this.data.employee.value,
          },
        ],
      };
      if (
        this.config.qty_report_heading &&
        this.config.qty_report_heading !== ""
      ) {
        qty.alternativeLabel = this.config.qty_report_heading;
      }
      tabs.push(qty);

      return tabs;
    },
  },
  watch: {
    $route(to) {
      if (this.loaded && to.params.machine) {
        this.loaded = false;
        let promises = [];
        this.data = {
          employee: {
            value: null,
            fullname: null,
          },
          order: {
            selected: null,
          },
          quantity: {
            value: null,
          },
        };

        promises.push(
          store.dispatch("machines/loadSignedInForMachine", to.params.machine)
        );
        Promise.all(promises).then(() => {
          this.loaded = true;
          console.log("fully loaded..");
        });
      }
    },
  },
};
</script>